<template>
  <div class="app-footer">
    <a-row type="flex" align="middle">
      <a-col :xs="24" :sm="12">
        <div class="app-footer__nav">
          <nav>
            <router-link to="/regulation">
              <a-button type="link">
                Regulamin
              </a-button>
            </router-link>
          </nav>
        </div>
      </a-col>
      <a-col :xs="0" :sm="12">
        <div class="app-footer__logo">Powered by</div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: $c1;
  max-width: $container-width;
  min-height: $footer-height;
  color:white;
  padding: 0 10px;
  margin: 0 auto;
  .app-footer__logo {
    float: right;
    width: 150px;
    font-size: 12px;
    background-position-x: 70px;
    background-repeat: no-repeat;
    height: 44px;
    background-image: url("~@/assets/images/logo-homedoctor-white.png");
  }
  .app-footer__nav {
    display: flex;

    nav {
      display: contents;
    }

    .ant-btn-link {
      margin-right: 10%;
      font-size: 12px;
      color: white;
    }
  }
}
</style>
