import { reactive, toRefs } from "vue";
export const storeRaw = reactive({
    user: JSON.parse(localStorage.getItem('user')) || {}
  })
export const store = toRefs(
    storeRaw
);

export const actions = {
    setUser(data){
        storeRaw.user = data;
        localStorage.setItem('user', JSON.stringify(data));
    }
}
