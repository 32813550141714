<template>
  <a-layout-header>
    <app-header />
  </a-layout-header>
  <a-layout-content>
    <router-view />
  </a-layout-content>
  <a-layout-footer>
    <app-footer />
  </a-layout-footer>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

import { store, actions } from "@/store";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      store,
    };
  },
  created: function() {
    this.$session().init();

    this.$http.interceptors.response.use(
      (response) => {
        if (this.store.user.expiresTimeout) {
          this.store.user.expiresOn =
            new Date().getTime() + this.store.user.expiresTimeout;
          actions.setUser(this.store.user);
        }
        return response;
      },
      (error) => {
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        if (error.config.url == `patient-portal/api/token/refresh`) {
          actions.setUser({});
          this.$http.defaults.headers.common = {};
          this.$router.push({ name: "Login" });
          this.$notification.open({
            message: "Twoja sesja wygasła",
            class: "error",
            duration: 0,
          });
        } else if (this.store.user.accessToken) {
          this.$http.defaults.headers.common = {};
          return this.$http
            .post("patient-portal/api/token/refresh", {
              refreshToken: this.store.user.refreshToken,
            })
            .then((res) => {
              const config = error.config;
              this.$http.defaults.headers.common = {
                Authorization: `Bearer ${res.data.access_token}`,
              };
              config.headers = {
                Authorization: `Bearer ${res.data.access_token}`,
              };
              this.store.user.accessToken = res.data.access_token;
              this.store.user.refreshToken = res.data.refresh_token;
              this.store.user.expiresTimeout =
                res.data.refresh_expires_in * 1000;
              this.store.user.expiresOn =
                new Date().getTime() + res.data.refresh_expires_in * 1000;
              actions.setUser(this.store.user);

              return new Promise((resolve, reject) => {
                this.$http
                  .request(config)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        } else {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      }
    );
  },
};
</script>

<style lang="scss">
body {
  color: black;
}
a {
  color: $c3;
  &:hover {
    color: $c3;
  }
}

.ant-layout-header {
  background-color: white;
  min-height: $header-height;
  padding: 0;
}

.ant-layout-content {
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  background-color: $g2;
}

.ant-layout-footer {
  background-color: $c1;
  min-height: $footer-height;
  margin: 0 auto;
  padding: 0;
}

.ant-alert {
  border: none;
  color: $c3;
}

.ant-alert-with-description {
  padding-top: 26px;
  padding-bottom: 26px;
  padding-right: 26px;
  .ant-alert-icon {
    top: 26px;
  }
}
.ant-alert-info {
  background-color: $c4;
  color: $c1;
  .ant-alert-message {
    &:empty {
      display: none;
    }
  }
  a {
    color: $c1;
  }
  .ant-alert-icon {
    color: $c1;
    background-color: $c4;
  }
}
.ant-row-flex {
  height: inherit;
  min-height: inherit;
}

.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $g3;
}
.ant-checkbox {
  box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.12);
}
.ant-checkbox-checked .ant-checkbox-inner {
  &::after {
    border-color: $c3;
  }

  background-color: transparent;
  color: $c3;
  border-color: $g3;
}

.ant-input {
  border-radius: 6px;
  border-color: $g3;
  height: 40px;
  &:focus {
    border-color: $c3;
    box-shadow: 0 0 0 2px rgba($c3, 0.2);
  }
}

.ant-btn {
  border-color: $c3;
  color: $c3;
  &:hover,
  &:focus {
    border-color: $c3;
    color: $c3;
  }
  &.ant-btn-link {
    color: $c3;
    padding: 0;
    border: none;
    font-weight: bold;
    &[disabled] {
      color: $g4 !important;
    }
  }

  &.ant-btn-primary {
    border-radius: 3px;
    background-color: $c3;
    border-color: $c3;
    color: white;
    &:hover {
      background-color: $c3;
      border-color: $c3;
    }
  }

  &.ant-btn-primary-disabled,
  &.ant-btn-primary.disabled,
  &.ant-btn-primary[disabled],
  &.ant-btn-primary-disabled:hover,
  &.ant-btn-primary.disabled:hover,
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary-disabled:focus,
  &.ant-btn-primary.disabled:focus,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary-disabled:active,
  &.ant-btn-primary.disabled:active,
  &.ant-btn-primary[disabled]:active,
  &.ant-btn-primary-disabled.active,
  &.ant-btn-primary.disabled.active,
  &.ant-btn-primary[disabled].active {
    color: white;
    background-color: $g4;
    border-color: $g4;
  }
}

.ant-drawer-close {
  color: $g1;
}

.ant-table {
  color: $g1;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-table-body {
  border-left: 1px solid $g3;
  border-right: 1px solid $g3;
  display: table;
  width: 100%;
}
.ant-table-thead {
  > tr > th {
    background: white;
    border-bottom: 1px solid $g3;
    border-top: 1px solid $g3;
  }
}
.ant-table-placeholder {
  border: 1px solid $g3;
}

.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    font-weight: bold;
    a,
    a:hover {
      color: black !important;
    }
    &.ant-dropdown-menu-item-active {
      background-color: white;
    }
  }
}

.ant-breadcrumb {
  a {
    color: $c1;
    &:hover {
      color: $c1;
    }
  }
}

.ant-form {
  color: black;
}

.ant-pagination-prev a,
.ant-pagination-next a,
.ant-pagination-item a {
  color: $g1;
}

.ant-pagination-item-link,
.ant-pagination-disabled a {
  border-color: $g3;
}

.ant-pagination-item-active {
  border-color: $c3;
  a {
    color: $c3;
  }
}
.ant-notification {
  .ant-notification-notice {
    word-break: break-all;
    box-shadow: none;
    .ant-notification-notice-message {
      margin-bottom: 0;
      font-size: 12px;
    }
    .ant-notification-notice-description {
      margin-top: 8px;
      font-size: 12px;
    }
    .ant-notification-notice-description:empty {
      margin-top: 0px;
    }
    &.success {
      background: lighten($c5, 40%);
      .ant-notification-notice-message,
      .ant-notification-notice-description,
      .ant-notification-notice-close {
        color: $c5;
      }
    }
    &.error {
      background: lighten($c6, 40%);
      .ant-notification-notice-message,
      .ant-notification-notice-description,
      .ant-notification-notice-close {
        color: $c6;
      }
    }
  }
}

.ant-skeleton {
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-top: 0;
    }
  }
}

.ant-upload-list {
  &.ant-upload-list-text {
    .ant-upload-list-item {
      background-color: $c4;
      height: 34px;

      .ant-upload-list-item-card-actions {
        opacity: 1;
        padding-right: 12px;
        .anticon {
          color: black;
        }
      }

      .ant-upload-list-item-info {
        > span {
          display: flex;
          align-items: center;
          .anticon-paper-clip {
            display: none;
          }
          .ant-upload-list-item-name {
            padding: 0 36px 0 12px;
            color: $c1;
          }
        }
      }
    }
  }
}

.ant-modal-content {
  .ant-modal-header {
    border-bottom: 0;
    padding: 24px 24px 12px 24px;
    .ant-modal-title {
      font-size: 20px;
    }
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 16px 24px 24px 24px;
    text-align: left;
    .ant-btn {
      height: 40px;
    }
  }
}
</style>
