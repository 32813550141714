import { createI18n } from 'vue-i18n'

import pl from './pl'

export function setupI18n(locale = 'pl') {
  const i18n = createI18n({
    locale,
    fallbackLocale: 'pl',
    messages: {
      pl
    }
  })
  setI18nLanguage(i18n, locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  i18n.global.locale.value = locale
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}