export default {
  dateOfBirth: "1997-12-16",
  email: "test@test.pl",
  firstName: "Konrad",
  id: 30,
  lastName: "Kowalski",
  pesel: "00000025885",
  phoneNumber: "+48609165419",
  providerId: "bcbc1174-a6f6-4c2a-b424-8274ac70ee75",
  termsAgreement: true
};
