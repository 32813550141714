import { storeRaw, actions } from "@/store";
import { watch } from "vue";

export default {
  install: (app) => {
    app.mixin({
      methods: {
        $session() {
          return {
            init: () => {
              if (storeRaw.user.accessToken) {
                if (storeRaw.user.expiredOn < new Date().getTime()) {
                  actions.setUser({});
                  this.$http.defaults.headers.common = {};
                  const stopCheckingRoute = watch(
                    () => this.$route.name,
                    () => {
                      this.$notification.open({
                        message: "Twoja sesja wygasła",
                        class: "error",
                        duration: 0,
                      });
                      if (this.$route.meta.requiresAuth) {
                        this.$router.push({
                          name: "Login",
                          query: {
                            redirectedFrom: this.$route.fullPath,
                          },
                        });
                      }
                      stopCheckingRoute();
                    }
                  );
                } else {
                  let sessionTimeout =
                    storeRaw.user.expiresOn - new Date().getTime();
                  setTimeout(() => {
                    actions.setUser({});
                    this.$http.defaults.headers.common = {};

                    this.$notification.open({
                      message: "Twoja sesja wygasła",
                      class: "error",
                      duration: 0,
                    });
                    if (this.$route.meta.requiresAuth) {
                      this.$router.push({
                        name: "Login",
                        query: {
                          redirectedFrom: this.$route.fullPath,
                        },
                      });
                    }
                  }, sessionTimeout);
                  this.$http.defaults.headers.common = {
                    Authorization: `Bearer ${storeRaw.user.accessToken}`,
                  };
                }
              }
            },
          };
        },
      },
    });
  },
};
