import { createRouter, createWebHistory } from "vue-router";
import { storeRaw } from "../store";
import { Modal } from "ant-design-vue";
import Login from "@/views/Login.vue";
import { axios } from "@/main";
import { actions } from "@/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (storeRaw.user.accessToken) {
        next("/");
      }
      next();
    },
  },
  {
    path: "/regulation",
    name: "Regulation",
    component: () =>
      import(/* webpackChunkName: "regulation" */ "../views/Regulation.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/agreements",
    name: "Agreements",
    meta: { requiresAuth: true, whitelist: ["DOCTOR", "PATIENT"] },
    beforeEnter: (to, from, next) => {
      if (storeRaw.user.agreements) {
        next("/");
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "agreements" */ "../views/Agreements.vue"),
  },
  {
    path: "/verify",
    name: "Verify",
    props: true,
    beforeEnter: (to, from, next) => {
      if (!to.params.phoneNumber || !to.params.pesel) {
        next("/login");
      }
      next();
    },
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Verify.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true, whitelist: ["PATIENT"] },
    component: () =>
      import(/* webpackChunkName: "regulation" */ "../views/Profile.vue"),
  },
  {
    path: "/",
    name: "Visits",
    meta: { requiresAuth: true, whitelist: ["PATIENT"] },
    component: () =>
      import(/* webpackChunkName: "visits" */ "../views/Visits.vue"),
  },
  {
    path: "/visit/:id",
    name: "Visit",
    props: true,
    meta: { requiresAuth: true, whitelist: ["PATIENT"] },
    component: () =>
      import(/* webpackChunkName: "visit" */ "../views/Visit.vue"),
  },
  {
    path: "/chat/:id",
    name: "Chat",
    props: true,
    meta: { requiresAuth: true, whitelist: ["DOCTOR", "PATIENT"] },
    component: () => import(/* webpackChunkName: "chat" */ "../views/Chat.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const proceedAfterLogin = (to, from, next) => {
    if (!storeRaw.user.agreements && to.name !== "Agreements") {
      next({
        path: "/agreements",
        query: {
          redirectedFrom: to.path,
        },
      });
    } else if (to.matched.some((record) => record.meta.whitelist)) {
      if (
        to.matched.some(
          (record) =>
            record.meta.whitelist.filter((element) =>
              storeRaw.user.roles.includes(element)
            ).length > 0
        )
      ) {
        next();
      } else {
        if (from.name) {
          next(false);
        } else {
          const firstAllowedRoute = routes.find((route) => {
            if (route.meta && route.meta.whitelist) {
              return (
                route.meta.whitelist.filter((element) =>
                  storeRaw.user.roles.includes(element)
                ).length > 0 &&
                route.name !== "Agreements" &&
                !route.props
              );
            }
          });
          if (firstAllowedRoute) {
            next({ path: firstAllowedRoute.path });
          } else {
            actions.setUser({});
            next({
              path: "/login",
            });
          }
        }
      }
    } else {
      next();
    }
  };
  Modal.destroyAll();
  if (!to.name) {
    router.push("/");
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!storeRaw.user.accessToken) {
      if (to.query.token_id) {
        axios
          .get(`patient-portal/api/token/${to.params.id}/${to.query.token_id}`)
          .then((res) => {
            const user = JSON.parse(atob(res.data.access_token.split(".")[1]));
            axios.defaults.headers.common = {
              Authorization: `Bearer ${res.data.access_token}`,
            };
            actions.setUser({
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
              expiresOn:
                new Date().getTime() + res.data.refresh_expires_in * 1000,
              expiresTimeout: res.data.refresh_expires_in * 1000,
              roles: user.realm_access.roles,
              agreements: true,
            });
            proceedAfterLogin(to, from, next);
          });
      } else {
        next({
          path: "/login",
          query: {
            redirectedFrom: to.path,
          },
        });
      }
    } else {
      proceedAfterLogin(to, from, next);
    }
  } else {
    next();
  }
});

export default router;
