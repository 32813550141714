export default {
  content: [
    {
      id: "129673223",
      appointmentAt: "2020-10-06T12:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "PENDING",
    },
    {
      id: "129623213",
      appointmentAt: "2020-10-06T11:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "12968223",
      appointmentAt: "2020-10-06T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "PAID",
    },
    {
      id: "122378223",
      appointmentAt: "2020-10-04T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "12853423",
      appointmentAt: "2020-10-01T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "1745899223",
      appointmentAt: "2020-10-02T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "123456333",
      appointmentAt: "2020-09-28T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "17887523",
      appointmentAt: "2020-09-29T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "1234767823",
      appointmentAt: "2020-09-11T10:29:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "17622436543",
      appointmentAt: "2020-09-23T16:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "89236542",
      appointmentAt: "2020-09-16T12:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "FINISHED",
    },
    {
      id: "111118223",
      appointmentAt: "2020-09-23T10:49:47.955Z",
      doctor: "Jan Kowalski",
      contractorId: 7,
      processStatus: "PAID",
    },
  ],
  totalElements: 12,
  size: 10,
  pageable: {
    pageNumber: 0
  },
};
