export default {
  access_token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MDMyODk4MzMsImlhdCI6MTYwMjkyMDU0OSwianRpIjoiMjhhOGI5ZjktZWFjMC00MzkyLTlhNjktNmVhMGY3YjI0NjlmIiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJwcC1wenUtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImI2MmQyNjI5LWI0MDItNDRmMy04MTUyLWM1YmNhY2Q4NzRjZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9iYWNrZW5kLnVhdC5ob21lZG9jdG9yLnBsIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsIkRPQ1RPUiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiQWRhbSBBZGFtIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiMDAwMDAwMjU4ODUiLCJnaXZlbl9uYW1lIjoiQWRhbSIsImZhbWlseV9uYW1lIjoiQWRhbSJ9.3WMKtZOzJOLQfKIHGJi-YafRjd4TlKqanME074Ma9Uk",
  expires_in: 300,
  refresh_expires_in: 600,
  refresh_token:
    "deyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5YjUyMzBjOC0wZmI3LTQyMjEtODY0ZS0zOWU1MmRlMjJiYTEifQ.eyJleHAiOjE2MDI5MjIzNDksImlhdCI6MTYwMjkyMDU0OSwianRpIjoiYTA1MDA3ZGMtYWY2YS00MDEyLWJiNzItYWEwYWFjNzg1MDQ2IiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6Imh0dHBzOi8vYXV0aG9yaXphdGlvbi51YXQuaG9tZWRvY3Rvci5wbC9hdXRoL3JlYWxtcy9wcC1wenUiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoicHAtcHp1LWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJiNjJkMjYyOS1iNDAyLTQ0ZjMtODE1Mi1jNWJjYWNkODc0Y2QiLCJzY29wZSI6InByb2ZpbGUgZW1haWwifQ.Br0Yjs05WpeuoQ7_1uyH-sxNJZ5JA3Fk5qnx1AcoPos",
  token_type: "bearer",
  id_token: null,
  "not-before-policy": 0,
  session_state: "b62d2629-b402-44f3-8152-c5bcacd874cd",
  scope: "profile email",
};
