import { Server, Response } from "miragejs";
import visits from "./data/visits";
import visit from "./data/visit";
import documents from "./data/documents";
import document from "./data/document";
import profile from "./data/profile";
import userDoctor from "./data/userDoctor";
import user from "./data/user";
import chat from "./data/chat";

import _ from "lodash";

let chatIterator = 1;

export function makeServer({ environment = "development" } = {}) {
  let server = new Server({
    environment,

    routes() {
      this.namespace = "";

      this.post(
        "patient-portal/api/sms-login",
        () => {
          const headers = {};
          return new Response(200, headers);
        },
        { timing: 2000 }
      );

      this.post(
        "patient-portal/api/sms-login/verify",
        () => {
          const headers = {};
          return new Response(200, headers, user);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/appointments",
        (schema, request) => {
          const page = Number(request.queryParams.page) || 0;
          const pageSize = Number(request.queryParams.pageSize) || 10;
          const sort = request.queryParams.sort
            ? request.queryParams.sort.split(",")
            : [];
          const headers = {};
          let res = { ...visits };
          if (sort.length > 0) {
            res.content = _.orderBy(
              res.content,
              [
                function(o) {
                  switch (sort[0]) {
                    case "processStatus": {
                      return o.processStatus;
                    }
                    case "appointmentAt": {
                      return new Date(o.appointmentAt);
                    }
                    case "doctor": {
                      return o.firstName;
                    }
                    default: {
                      return o.processStatus;
                    }
                  }
                },
              ],
              sort[1].toLowerCase()
            );
          }
          res.content = res.content.slice(
            page * pageSize,
            page * pageSize + pageSize
          );
          res.pageable.pageNumber = page;
          res.pageable.pageSize = pageSize;

          return new Response(200, headers, res);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/patient-profile",
        () => {
          const headers = {};
          return new Response(200, headers, profile);
        },
        { timing: 2000 }
      );

      this.put(
        "patient-portal/api/patient-profile",
        () => {
          const headers = {};
          return new Response(200, headers, profile);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/conversation/:id",
        () => {
          const headers = {};
          chatIterator++;
          return new Response(200, headers, chatIterator % 2 ? chat : []);
        },
        { timing: 1000 }
      );

      this.post(
        "patient-portal/api/conversation/message/:id",
        () => {
          const headers = {};
          return new Response(200, headers);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/appointments/:id",
        () => {
          const headers = {};
          return new Response(200, headers, visit);
        },
        { timing: 1000 }
      );

      this.get(
        "patient-portal/api/documentation/:id",
        (schema, request) => {
          const page = Number(request.queryParams.page) || 0;
          const pageSize = Number(request.queryParams.pageSize) || 10;
          const sort = request.queryParams.sort
            ? request.queryParams.sort.split(",")
            : [];
          const headers = {};

          let res = { ...documents };
          if (sort.length > 0) {
            res.content = _.orderBy(
              res.content,
              [
                function(o) {
                  switch (sort[0]) {
                    case "fileName": {
                      return o.fileName;
                    }
                    case "uploadedAt": {
                      return new Date(o.uploadedAt);
                    }
                    case "uploadedBy": {
                      return o.uploadedBy;
                    }
                    default: {
                      return o.processStatus;
                    }
                  }
                },
              ],
              sort[1].toLowerCase()
            );
          }
          res.content = res.content.slice(
            page * pageSize,
            page * pageSize + pageSize
          );
          res.pageable.pageNumber = page;
          res.pageable.pageSize = pageSize;

          return new Response(200, headers, res);
        },
        { timing: 2000 }
      );

      this.post(
        "patient-portal/api/documentation/:id",
        () => {
          const headers = {};
          return new Response(200, headers);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/documentation/download/:id",
        () => {
          console.log(document);
          const headers = { "Content-Type": "arraybuffer" };
          return new Response(200, headers, JSON.stringify(document));
        },
        { timing: 2000 }
      );

      this.post(
        "patient-portal/api/refreshToken",
        () => {
          const headers = {};
          return new Response(200, headers, {
            accessToken:
              "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ1eDJmVDhrTFlnSDdLWl9BcVdKUHVQVkhjQUZuNnVkWU53dVJNUEtSLVdVIn0.eyJleHAiOjE2MDI5NTY1NDksImlhdCI6MTYwMjkyMDU0OSwianRpIjoiMjhhOGI5ZjktZWFjMC00MzkyLTlhNjktNmVhMGY3YjI0NjlmIiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJwcC1wenUtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6ImI2MmQyNjI5LWI0MDItNDRmMy04MTUyLWM1YmNhY2Q4NzRjZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9iYWNrZW5kLnVhdC5ob21lZG9jdG9yLnBsIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsIlBBVElFTlQiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IkFkYW0gQWRhbSIsInByZWZlcnJlZF91c2VybmFtZSI6IjAwMDAwMDI1ODg1IiwiZ2l2ZW5fbmFtZSI6IkFkYW0iLCJmYW1pbHlfbmFtZSI6IkFkYW0ifQ.Qx0Le9jMa1tWIjLGuIRUL6DONj80KDrwt02aXRBTXe2SdqDOH2cN2haF2sjoVUHNZ4QSLbjpdhV7F829A8EWZYXDb9hJVmFPLVrw6nxaA0uXQXqt6e3C09QXPYk31HdTiGf7Z5kKMYtZ5ioNtiFD9XK4o-xPCHu4nOqtaa7ZECBi5UjQoLu8XUc33UpkSq834ScoHYKIZJS1NHjJ3CIWldP0kiS0rx-FM2l1VosYawbCsvmpVuR5DrSz5FX6lDkSrSiavRbOLowfbgZb00_EWZAmYv-TmXEjTGRyrYupCrToEPZ5uK3HoSjHkcexpMgUTF9pVWryGRJEXYgHGdWfWw",
            refreshToken:
              "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5YjUyMzBjOC0wZmI3LTQyMjEtODY0ZS0zOWU1MmRlMjJiYTEifQ.eyJleHAiOjE2MDI5MjIzNDksImlhdCI6MTYwMjkyMDU0OSwianRpIjoiYTA1MDA3ZGMtYWY2YS00MDEyLWJiNzItYWEwYWFjNzg1MDQ2IiwiaXNzIjoiaHR0cHM6Ly9hdXRob3JpemF0aW9uLnVhdC5ob21lZG9jdG9yLnBsL2F1dGgvcmVhbG1zL3BwLXB6dSIsImF1ZCI6Imh0dHBzOi8vYXV0aG9yaXphdGlvbi51YXQuaG9tZWRvY3Rvci5wbC9hdXRoL3JlYWxtcy9wcC1wenUiLCJzdWIiOiJiY2JjMTE3NC1hNmY2LTRjMmEtYjQyNC04Mjc0YWM3MGVlNzUiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoicHAtcHp1LWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJiNjJkMjYyOS1iNDAyLTQ0ZjMtODE1Mi1jNWJjYWNkODc0Y2QiLCJzY29wZSI6InByb2ZpbGUgZW1haWwifQ.Br0Yjs05WpeuoQ7_1uyH-sxNJZ5JA3Fk5qnx1AcoPos",
          });
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/agreements",
        () => {
          const headers = {};
          return new Response(200, headers, false);
        },
        { timing: 2000 }
      );

      this.put(
        "patient-portal/api/agreements",
        () => {
          const headers = {};
          return new Response(200, headers, true);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/token/:id/:token_id",
        () => {
          const headers = {};
          return new Response(200, headers, userDoctor);
        },
        { timing: 2000 }
      );

      this.post(
        "patient-portal/api/token/refresh",
        (schema, request) => {
          const headers = {};
          const reqBody = JSON.parse(request.requestBody);
          let resBody = {};
          if (reqBody.refreshToken[0] === "p") {
            resBody = user;
          } else {
            resBody = userDoctor;
          }
          return new Response(200, headers, resBody);
        },
        { timing: 2000 }
      );

      this.post(
        "patient-portal/api/contact/message",
        () => {
          const headers = {};
          return new Response(200, headers);
        },
        { timing: 2000 }
      );

      this.get(
        "patient-portal/api/notification",
        () => {
          const headers = {};
          return new Response(200, headers, []);
        },
        { timing: 2000 }
      );
      this.put(
        "patient-portal/api/notification/deactivate",
        () => {
          const headers = {};
          return new Response(200, headers);
        },
        { timing: 2000 }
      );

      
    },
  });

  return server;
}
