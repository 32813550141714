import { createApp } from "vue";
import Antd from "ant-design-vue";
import Axios from "axios";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import router from "./router";
import { makeServer } from "./mock/server";
import SessionService from "./services/Session";
import { setupI18n } from './i18n'

const i18n = setupI18n()

if (process.env.VUE_APP_MOCKUP === "true") {
  makeServer();
  Axios.defaults.baseURL = "/";
} else {
  Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
}

export const axios = Axios;

const app = createApp(App);
app.config.globalProperties.$http = Axios;
app.provide("mode", process.env.VUE_APP_MODE);
app.provide("recaptchaSiteKey", process.env.VUE_APP_RECAPTCHA_SITE_KEY);
app.config.productionTip = false;
app.use(router);
app.use(i18n);
app.use(Antd);
app.use(SessionService);
app.mount("#app");
